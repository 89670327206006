exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-notes-js": () => import("./../../../src/pages/notes.js" /* webpackChunkName: "component---src-pages-notes-js" */),
  "component---src-pages-price-js": () => import("./../../../src/pages/price.js" /* webpackChunkName: "component---src-pages-price-js" */),
  "component---src-pages-service-js": () => import("./../../../src/pages/service.js" /* webpackChunkName: "component---src-pages-service-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-templates-blogarchive-template-js": () => import("./../../../src/templates/blogarchive-template.js" /* webpackChunkName: "component---src-templates-blogarchive-template-js" */),
  "component---src-templates-blogpost-template-js": () => import("./../../../src/templates/blogpost-template.js" /* webpackChunkName: "component---src-templates-blogpost-template-js" */),
  "component---src-templates-categoryarchive-template-js": () => import("./../../../src/templates/categoryarchive-template.js" /* webpackChunkName: "component---src-templates-categoryarchive-template-js" */),
  "component---src-templates-typearchive-template-js": () => import("./../../../src/templates/typearchive-template.js" /* webpackChunkName: "component---src-templates-typearchive-template-js" */),
  "component---src-templates-worksarchive-template-js": () => import("./../../../src/templates/worksarchive-template.js" /* webpackChunkName: "component---src-templates-worksarchive-template-js" */),
  "component---src-templates-workspost-template-js": () => import("./../../../src/templates/workspost-template.js" /* webpackChunkName: "component---src-templates-workspost-template-js" */)
}

